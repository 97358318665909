import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BeforeSlideDetail} from "lightgallery/lg-events";
import lgZoom from 'lightgallery/plugins/zoom';
import {Router} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {environment} from "../../../environments/environment";
import {LightGallery} from 'lightgallery/lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgVideo from 'lightgallery/plugins/video';
@Component({
  selector: 'app-photography',
  templateUrl: './photography.component.html',
  styleUrls: ['./photography.component.scss'],
})
export class PhotographyComponent implements OnInit {
  photographyAPIData = [];
  resourceBaseURL = '';
  photographItems: any[] = [];
  showAuthBtn = false;
  constructor(private ApiService: ApiService, private route: Router) {
    this.resourceBaseURL = environment.resourceURL;
  }

  ngOnInit(): void {
    this.getPosts('');
    this.showAuthBtn = this.ApiService.isAuthenticated();
  }

  onInit = (detail: any): void => {
    this.lightGallery = detail.instance;
    console.log(this.lightGallery)
  };
  private lightGallery!: LightGallery;
  private needRefresh = false;

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh(this.photographItems);
      // this.lightGallery.openGallery();
      this.needRefresh = false;
    }
  }

  settings = {
    counter: true,
    download: false,
    dynamic: true,
    dynamicEl: this.photographItems,
    plugins: [lgZoom, lgVideo],
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const {index, prevIndex} = detail;
  };

  openGallery(index: number) {
    this.lightGallery.openGallery(index);
  };

  getPosts(tag: string = ''): void {
    let data: Object = {
      categories: "photography",
      sort: { createdAt: -1 }
    };

    if (tag != '') {
      // fetch post with tags
      Object.assign(data, {
        project_tags: tag,
        technology_tags: tag
      });
    }
    let self = this;
    this.ApiService.getPosts(data, 'game-development').then((responseData) => {
        this.photographyAPIData = responseData;
        for (let i = 0; i < this.photographyAPIData.length; i++) {
          let photoitem = {
            id: i.toString(),
            pid: this.photographyAPIData[i]['id'],
            src: this.resourceBaseURL + this.photographyAPIData[i]['display_image_URL'],
            subHtml: `<div class="lightGallery-captions">
            <h4>${this.photographyAPIData[i]['title']}</h4>
            <p>${this.photographyAPIData[i]['description']}</p>
        </div>`
          };
          this.photographItems.push(photoitem);
        }
      this.lightGallery.refresh(this.photographItems);
      }

    ).catch((error: Error) => {
        console.error(error);
      }
    );
    return;
  }

  deletePost($event: MouseEvent, photographyCard: any) {
    $event.stopPropagation();
    this.ApiService.deletePost(photographyCard.pid).then((resp)=> {
      window.location.reload();
    }).catch(excep => {
      console.error(excep);
    });
  }
}
