import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-grid-masonry-item',
  templateUrl: './grid-masonry-item.component.html',
  styleUrls: ['./grid-masonry-item.component.scss']
})
export class GridMasonryItemComponent {
  displayText = "Unreal";
}
