<div class="login-wrapper">
  <form [formGroup]="loginForm" (ngSubmit)="OnLoginSubmit()" class="container row col-md-4 justify-content-md-center">
      <div class="form-group">
        <label for="exampleInputEmail1">Email address</label>
        <input type="text" formControlName="email" id="email" class="form-control input-control" placeholder="Email Address">
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Password</label>
        <input type="password" formControlName="password" id="password" class="form-control input-control" placeholder="Password">
      </div>
    <br>
    <br>
      <div class="form-group">
        <button type="submit" id="Login" class="btn btn-login ">LOGIN</button>
      </div>
  </form>
</div>
