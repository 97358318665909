import { Component } from '@angular/core';

@Component({
  selector: 'app-info-btn',
  templateUrl: './info-btn.component.html',
  styleUrls: ['./info-btn.component.scss']
})
export class InfoBtnComponent {

}
