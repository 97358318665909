import {Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {GameDevDialogBoxComponent} from "../dialogBox/game-dev-dialog-box/game-dev-dialog-box.component";
import {environment} from "../../../../environments/environment";

declare var VanillaTilt: any;
@Component({
  selector: 'app-display-card',
  templateUrl: './display-card.component.html',
  styleUrls: ['./display-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: "card",
    "(click)": "onCardClick($event)"
  }
})
export class DisplayCardComponent {

  resourceBaseURL = '';
  constructor(public dialog: MatDialog) {
    this.resourceBaseURL = environment.resourceURL;
  }
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef | undefined;
  @Input() cardData = {
    title: '',
    description: '',
    display_image_URL: '',
    preview_tags:  [
    ],
    project_tags: [
      {
        title: '',
      },
    ],
    technology_tags: [
      {
        title: '',
      },
    ],
    links: [
      {
        link_url: '',
        title: ''
      }
    ],
  };

  ngOnInit(): void {
    if(this.cardElement) {
      VanillaTilt.init(document.querySelector(".card"), {max: 25, speed: 400});
      VanillaTilt.init(document.querySelectorAll(".tilt-image"));
    }
  }


  onCardClick(event: Event): void {
    // console.log(this.cardElement?.nativeElement);
    const dialogRef = this.dialog.open(GameDevDialogBoxComponent, {
      data: this.cardData,
      width: '75%'
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  removePost(event: MouseEvent): void {
    event.stopImmediatePropagation();
    // console.log("event");
  }
}
