<div class="container create-post-wrapper">

  <label>Title</label>
  <input class="input" type="text" [(ngModel)]="responseData.title"/>

  <label>Display Image</label>
  <button type="button" class="file-btn" (click)="displayImageInput.click()">Upload Display
    Image: {{ inputSettings.display_image_data.name}}</button>
  <input hidden (change)="onDisplayImageSelected($event)" #displayImageInput type="file" id="displayImageInput"
         accept="image/*">

  <label>Description</label>
  <textarea class="input" type="text" [(ngModel)]="responseData.description"></textarea>

  <label>Categories</label>
  <mat-form-field appearance="fill">
    <mat-select [(ngModel)]="inputSettings.category">
      <mat-option *ngFor="let category of inputSettings.categories" [value]="category">
        {{category}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="inputSettings.category != 'photography'" class="create-post-wrapper">
    <label>Project Tags</label>
    <mat-form-field class="example-chip-list" appearance="fill">
      <mat-chip-grid #chipGrid aria-label="Enter fruits">
        <mat-chip-row *ngFor="let section of responseData.project_tags"
                      (removed)="removeSection(section, 'project')"
                      [editable]="true"
                      (edited)="editSection(section, 'project', $event)">
          {{section}}
          <button matChipRemove [attr.aria-label]="'remove ' + section">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input placeholder="Add Technology Tags" style="color: #ffffff !important; "
               [matChipInputFor]="chipGrid"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event, 'project')"/>
      </mat-chip-grid>
    </mat-form-field>

    <label>Technology Tags</label>
    <mat-form-field class="example-chip-list" appearance="fill">
      <mat-chip-grid #techGrid aria-label="Enter fruits">
        <mat-chip-row *ngFor="let section of responseData.technology_tags"
                      (removed)="removeSection(section, 'technology')"
                      [editable]="true"
                      (edited)="editSection(section,'technology', $event)">
          {{section}}
          <button matChipRemove [attr.aria-label]="'remove ' + section">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
        <input placeholder="Add Technology Tags" style="color: #ffffff !important; "
               [matChipInputFor]="techGrid"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event, 'technology')"/>
      </mat-chip-grid>
    </mat-form-field>

    <!--  <mat-form-field appearance="outline">-->
    <!--    <mat-label>End Time</mat-label>-->
    <!--    <input matInput type="time" placeholder="HH:MM" id="end_time_hour" [formControl]="timeFormControl">-->
    <!--    <mat-select name="ampm" class="ampm" [(ngModel)]="event.eampm">-->
    <!--      <mat-option value="AM">AM</mat-option>-->
    <!--      <mat-option value="PM">PM</mat-option>-->
    <!--    </mat-select>-->
    <!--  </mat-form-field>-->

    <label>Screenshots Images</label>
    <ul>
      <li *ngFor="let imageObj of inputSettings.images; let i = index">
        <input class="input-select" [(ngModel)] = "imageObj.image_title"/>
        <input class="input-select" [(ngModel)] = "imageObj.image_description"/>
        <button (click)="OnRemoveScreenshotItem(i)" >
          <mat-icon olor="white" class="cancel-btn">cancel</mat-icon>
        </button>
      </li>
    </ul>
    <button class="file-btn" type="button" (click)="screenshotsInput.click()">Upload
      Screenshots {{ this.inputSettings.images.length }}</button>
    <input hidden multiple="multiple" (change)="onScreenshotsSelected($event)" #screenshotsInput type="file"
           id="screenshotsInput" accept="image/*">

    <br>
    <label>Video Links</label>
    <ul>
      <li *ngFor="let linkObj of responseData.videos; let i = index">
        <label>{{linkObj.title}}</label>
        <select class="dropdown-select" [(ngModel)]="linkObj.video_type">
          <option *ngFor="let type of inputSettings.videos_type" [value]="type">{{type}}</option>
        </select>
        <input class="input-select" placeholder="Type Url" [(ngModel)] = "linkObj.video_url"/>
        <button (click)="OnRemoveVideoURLItem(i)" >
          <mat-icon color="white" class="cancel-btn">cancel</mat-icon>
        </button>
      </li>
    </ul>
    <br>
    <button class="file-btn" type="button" (click)="AddVideoURLItem()">Add
      Video URL + </button>
    <br>
    <label>Project Share Links</label>
    <ul>
      <li *ngFor="let linkObj of responseData.links; let i = index">
        <label>{{linkObj.title}}</label>
        <input class="input-select" [(ngModel)] = "linkObj.link_url"/>
      </li>
    </ul>
    <br>

    <label>Description Blog</label>
    <quill-editor [(ngModel)]="responseData.description_blog"
                  placeholder="Enter Text"
                  [modules]="quillConfig"
                  (onSelectionChanged)="onSelectionChanged($event)"
                  (onContentChanged)="onContentChanged($event)"></quill-editor>

    <!-- <div>
      <pre>{{htmlText}}</pre>
    </div> -->

  </div>
  <br>
  <button type="button" class="submit-btn" (click)="updatePost()">UPDATE POST</button>

  <label>Description Blog Preview</label>
  <div [innerHTML]="responseData.description_blog">
  </div>
</div>
