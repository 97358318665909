import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public apiService: ApiService) { }

  @Input()
  currentRoute = '';

  ngOnInit(): void {
  }

  getRouteClass(itemName: string){
    let showClass = false;
    if(itemName == this.currentRoute) {
      showClass = true;
    }

      return {
      'active-page-item': showClass
    }

  }

  isLoggedIn() {
    return this.apiService.isAuthenticated();
  }

  logout() {
    this.apiService.logout();
  }
}
