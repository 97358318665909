import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {GameDevelopmentComponent} from "./components/game-development/game-development.component";
import {SoftwareDevelopmentComponent} from "./components/software-development/software-development.component";
import {PhotographyComponent} from "./components/photography/photography.component";
import {UiDesignComponent} from "./components/ui-design/ui-design.component";
import {HomeComponent} from "./components/home/home.component";
import {PagenotfoundComponent} from "./components/pagenotfound/pagenotfound.component";
import {LoginComponent} from "./components/login/login.component";
import {CreatePostComponent} from "./components/create-post/create-post.component";
import {AuthGuardService as AuthGuard } from "./services/auth-guard.service";
import {EditPostComponent} from "./components/edit-post/edit-post.component";

const routes: Routes = [
  {path:'game-development', component: GameDevelopmentComponent},
  {path:'software-development', component: SoftwareDevelopmentComponent},
  {path:'photography', component: PhotographyComponent},
  {path:'ui-design', component: UiDesignComponent},
  {path:'ce8ceed1b5b3143703a5dc67da2098e7', component: LoginComponent},
  {path:'', component: HomeComponent},
  {path:'ce8ceed1b5b3143703a5dc67da2098e7/createpost', component: CreatePostComponent, canActivate: [AuthGuard]},
  {path:'ce8ceed1b5b3143703a5dc67da2098e7/edit/post/:id', component: EditPostComponent, canActivate: [AuthGuard]},
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full',
    component: PagenotfoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
