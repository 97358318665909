<app-header></app-header>
<div class="cover-container d-flex h-100  mx-auto">
  <a class="home-container-item software-dev" routerLink="/software-development">
    <i class="fa-solid fa-code"></i>
    <h2> SOFTWARE DEVELOPMENT</h2>
  </a>
  <a class="home-container-item game-dev" routerLink="/game-development">
    <i class="fa-brands fa-xbox"></i>
    <h2> GAME DEVELOPMENT</h2>
  </a>
  <a class="home-container-item UI-Design" routerLink="/ui-design">
    <i class="fa-brands fa-figma"></i>
    <h2>UI/UX DESIGN</h2>
  </a>
  <a class="home-container-item photography" routerLink="/photography">
    <i class="fa-solid fa-camera"></i>
    <h2> PHOTOGRAPHY</h2>
  </a>
</div>

<div class="technology-container ">
  <div class="container">
    <div class="vert-border border color-border-muted "></div>
    <h1 class="section-heading hidden">TECHNICAL SKILLS</h1>
    <div class="technology-sub-container">
      <div class="inline-heading-container ">
        <div class="svg-btn">
          <svg style="right: calc(70% + 318px - 3px);" aria-hidden="true" height="28" viewBox="0 0 24 24" version="1.1"
               width="28" data-view-component="true" class="octicon octicon-git-commit">
            <path fill-rule="evenodd"
                  d="M15.5 11.75a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm1.444-.75a5.001 5.001 0 00-9.888 0H2.75a.75.75 0 100 1.5h4.306a5.001 5.001 0 009.888 0h4.306a.75.75 0 100-1.5h-4.306z"></path>
          </svg>
        </div>
        <h3 class="technology-sub-container-heading hidden">Software Development</h3>
      </div>
      <div class="technology-sub-container-1 hidden">
        <mat-chip-listbox class="chip-wrapper" #chip_wrapper id="chip_wrapper" aria-label="Fish selection">
          <label class="label">Languages:</label>
          <mat-chip>TypeScript</mat-chip>
          <mat-chip>Python</mat-chip>
          <mat-chip>C++</mat-chip>
          <mat-chip>Java</mat-chip>
          <mat-chip>PHP</mat-chip>
          <mat-chip>HTML</mat-chip>
          <mat-chip>CSS, SCSS</mat-chip>
        </mat-chip-listbox>
      </div>

      <div class="technology-sub-container-1 hidden">

        <mat-chip-listbox class="chip-wrapper" aria-label="Fish selection">
          <label class="label">Database:</label>
          <mat-chip>Mysql</mat-chip>
          <mat-chip>NoSql (Redis & MongoDB)</mat-chip>
        </mat-chip-listbox>
      </div>

      <div class="technology-sub-container-1 hidden">
        <mat-chip-listbox class="chip-wrapper" aria-label="Fish selection">
          <label class="label">Frameworks:</label>
          <mat-chip>Angular</mat-chip>
          <mat-chip>Node.js (Express)</mat-chip>
          <mat-chip>React</mat-chip>
          <mat-chip>Bootstrap</mat-chip>
          <mat-chip>React</mat-chip>
          <mat-chip>Android</mat-chip>
          <mat-chip>Laravel</mat-chip>
        </mat-chip-listbox>
      </div>

      <div class="technology-sub-container-1 hidden">
        <mat-chip-listbox class="chip-wrapper" aria-label="Fish selection">
          <label class="label">Others:</label>
          <mat-chip>Git</mat-chip>
          <mat-chip>Agile</mat-chip>
          <mat-chip>Trello</mat-chip>
          <mat-chip>Jira</mat-chip>
          <mat-chip>Confluence</mat-chip>
          <mat-chip>MVC</mat-chip>
          <mat-chip>Linux (Debian, Ubuntu)</mat-chip>
          <mat-chip>REST API</mat-chip>
        </mat-chip-listbox>
      </div>
    </div>

    <div class="technology-sub-container">
      <div class="inline-heading-container">
        <div class="svg-btn">
          <svg style="right: calc(70% + 318px - 3px);" aria-hidden="true" height="28" viewBox="0 0 24 24" version="1.1"
               width="28" data-view-component="true" class="octicon octicon-git-commit">
            <path fill-rule="evenodd"
                  d="M15.5 11.75a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0zm1.444-.75a5.001 5.001 0 00-9.888 0H2.75a.75.75 0 100 1.5h4.306a5.001 5.001 0 009.888 0h4.306a.75.75 0 100-1.5h-4.306z"></path>
          </svg>

          <h3 class="technology-sub-container-heading hidden">Game Development</h3>
        </div>
      </div>
      <div class="technology-sub-container-1 hidden">
        <mat-chip-listbox class="chip-wrapper" #chip_wrapper id="chip_wrapper" aria-label="Fish selection">
          <label class="label">Engines:</label>
          <mat-chip>Unreal Engine</mat-chip>
          <mat-chip>Unity</mat-chip>
        </mat-chip-listbox>
      </div>
      <div class="technology-sub-container-1 hidden">
        <mat-chip-listbox class="chip-wrapper" #chip_wrapper id="chip_wrapper" aria-label="Fish selection">
          <label class="label">Languages:</label>
          <mat-chip>C++</mat-chip>
          <mat-chip>Blueprints</mat-chip>
          <mat-chip>C#</mat-chip>
        </mat-chip-listbox>
      </div>
      <div class="technology-sub-container-1 hidden">

        <mat-chip-listbox class="chip-wrapper" aria-label="Fish selection">
          <label class="label">Design:</label>
          <mat-chip>Blender</mat-chip>
          <mat-chip>Substance Painter</mat-chip>
          <mat-chip>Figma</mat-chip>
          <mat-chip>Adobe Photoshop</mat-chip>
          <mat-chip>Adobe Illustrator</mat-chip>
          <mat-chip>Adobe After Effects</mat-chip>
          <mat-chip>Adobe Premiere Pro</mat-chip>
          <mat-chip>Substance Painter</mat-chip>
          <mat-chip>Quixel Mixer</mat-chip>
        </mat-chip-listbox>
      </div>
    </div>
  </div>
</div>

<div class="education-container container-fluid">
  <div class="container">
    <!--  <img src="https://github.githubassets.com/images/modules/site/copilot/bg-stroke-2.svg"-->
    <!--       width="318" height="1547"-->
    <!--       alt=""-->
    <!--       loading="lazy"-->
    <!--       decoding="async" style="" class="position-absolute">-->
    <div class="vert-border border color-border-muted "></div>
    <h1 class="section-heading hidden">EDUCATION</h1>
    <div class="education-sub-container">
      <div class="inline-heading-container">
        <div class="svg-btn-2">
          <i class="fa-solid fa-user-graduate"></i>
        </div>
        <h3 class="technology-sub-container-heading  education-sub-container-heading-main hidden">University Of
          Birmingham</h3>
        <h5 class="technology-sub-container-heading education-sub-container-heading hidden"> Sept 2021 - Sept 2022</h5>
      </div>
      <h5 class="technology-sub-container-heading education-sub-degree">Master's in Advanced Computer Science</h5>

      <div class="technology-sub-container-1 hidden">
        <ul class="subjects-home" aria-label="Fish selection">
          <li>Forensics, Malware and Penetration Testing</li>
          <li>Advanced Networking</li>
          <li>Intelligent Robotics, Mobile & Ubiquitous Computing</li>
          <li>Secure Software and Hardware Systems</li>
          <li>Human-Computer Interaction</li>
        </ul>
      </div>

    </div>
    <div class="education-sub-container">
      <div class="inline-heading-container">
        <div class="svg-btn-2">
          <i class="fa-solid fa-user-graduate"></i>
        </div>
        <h3 class="technology-sub-container-heading  education-sub-container-heading-main hidden">SRM University</h3>
        <h5 class="technology-sub-container-heading education-sub-container-heading hidden">May 2013- May 2017</h5>
      </div>
      <h5 class="technology-sub-container-heading education-sub-degree">B.Sc. in Computer Science & Engineering</h5>

      <div class="technology-sub-container-1 hidden">
        <ul class="subjects-home" aria-label="Fish selection">
          <li>Data Structures and Algorithm Design</li>
          <li>Programming Language Design and C Programming</li>
          <li>Object Oriented Programming</li>
          <li>Advanced Java Programming</li>
          <li>Computer Networks</li>
          <li>Operating Systems</li>
          <li>Discrete Mathematics</li>
        </ul>
      </div>

    </div>

  </div>
</div>

<!--<div class="technology-container container">-->
<!--  <img src="https://github.githubassets.com/images/modules/site/copilot/bg-stroke-2.svg" width="318" height="1547"-->
<!--       alt="" loading="lazy" decoding="async" style="top:-100px" class="position-absolute">-->
<!--</div>-->
<footer class="footer">
  <h6>© Copyright 2023 Taranjyot Singh</h6>
</footer>
