<header class="">
  <nav class="navbar fixed-top navbar-expand-lg bg-body-tertiary">
    <div class="container-fluid nav-wrapper">
      <a class="navbar-brand" href=""> <img style="height: 32px" src="assets/images/logo.png" alt=""></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
              aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fa-solid fa-bars navbar-toggle-icon" ></i>
      </button>
      <div class="collapse navbar-collapse"  id="navbarNav">
          <ul class="navbar-nav main-navbar-nav">


            <li class="nav-item"><a class="nav-link" routerLink="/software-development"
                                    [ngClass]="getRouteClass('software-development')">Software
              Development</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="/game-development"  [ngClass]="getRouteClass('game-development')"
            >Game Development</a>
            </li>
            <li class="nav-item"><a class="nav-link" routerLink="/ui-design" [ngClass]="getRouteClass('ui-design')"
                                    >UI Design</a></li>
            <li class="nav-item"><a class="nav-link" routerLink="/photography" [ngClass]="getRouteClass('photography')"
                                    >Photography</a></li>
            <li class="nav-item"><a class="nav-link"  (click)="logout()" *ngIf="isLoggedIn()"
            >Logout</a></li>
          </ul>
        <ul class="site-share-icons">
          <li class="share-icon"><a href="https://www.instagram.com/taransodyssey/"><i class="fa-brands fa-instagram "></i> </a></li>
          <li class="share-icon"><a href="https://www.linkedin.com/in/taranjyot-singh-394384123/"><i class="fa-brands fa-linkedin-in"></i></a></li>
          <li class="share-icon"><a href="https://github.com/Taranjyot"><i class="fa-brands fa-github"></i></a></li>
          <li class="share-icon"><a href="https://www.artstation.com/taranjyot_singh"><i class="fa-brands fa-artstation"></i></a></li>
         <!-- <li class="share-icon"><a href=""><i class="fa-brands fa-youtube"></i></a></li>-->
        </ul>
      </div>
    </div>

  </nav>
</header>
