import {Component, OnInit, HostListener, ViewChild, ElementRef, ViewEncapsulation} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import {GameDevelopmentResponse} from "../../MockData/GameDevelopemntMockData";
import {Router} from "@angular/router";
import {ApiService} from "../../services/api.service";

declare var VanillaTilt: any;
@Component({
  selector: 'app-game-development',
  templateUrl: './game-development.component.html',
  styleUrls: ['./game-development.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GameDevelopmentComponent implements OnInit {

  gameDevAPIData = [];
  categoriesTagsAPIData = [];
  @ViewChild('card') card!: ElementRef;
  onMouseMove(e: any): void {
    const cards = document.getElementsByClassName('card');
    for(let i=0; i < cards.length; i++) {
      const card = cards[i] as HTMLElement;
      const rect = card.getBoundingClientRect(),
        x = e.clientX - rect.left,
        y = e.clientY - rect.top;
      card.style.setProperty("--mouse-x", `${x}px`);
      card.style.setProperty("--mouse-y", `${y}px`);
    }
  }
  constructor (@Inject(DOCUMENT) private document: Document, private ApiService: ApiService, private route: Router) {
    console.log(route)
  }

  ngOnInit(): void {
    this.getPosts('');
    this.getDistinctTags('');
    //VanillaTilt.init(document.querySelector(".card"), { max: 25, speed: 400 });
    //VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }

  cardHoverEffect(): void {
    /*
    for(const card of document.querySelectorAll(".card")) {
      card.onmousemove = e => onMouseMove(e);
    }
    *
     */
  }

  getPosts(tag: string = ''): void {
    let data: Object = {
      categories: "game-development",
      sort: { createdAt: -1 }
    };

    if (tag != '') {
      // fetch post with tags
      Object.assign(data, {
        tags: tag,
      });
    }
    let self = this;
    this.ApiService.getPosts(data, 'game-development').then((responseData) => {
        this.gameDevAPIData = responseData;
      }
    ).catch( (error: Error) => {
        console.error(error);
      }
    );
    return;
  }

  getDistinctTags(tag: string = ''): void {
    let data: Object = {
      category: "game-development",
      search_key: 'preview_tags',
    };

    let self = this;
    this.ApiService.getDistinctTags(data, 'game-development').then((responseData) => {
        this.categoriesTagsAPIData = responseData;
      }
    ).catch( (error: Error) => {
        console.error(error);
      }
    );
    return;
  }

  onCategoryChange(title: any): void {
    if(title == 'Show All') {
      this.gameDevAPIData = [];
      this.getPosts('');
      return;
    }
    this.gameDevAPIData = [];
    this.getPosts(title);
  }

  onPageScroll(event:any) {
    console.log("End");
    // visible height + pixel scrolled >= total height
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      console.log("End");
    }
  }
}
