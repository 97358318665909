import {AfterViewInit, Component, ElementRef, Inject, OnInit} from '@angular/core';
import {Subject} from "rxjs";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit  {
  private observer = new IntersectionObserver((entries)=>{
    entries.forEach((entry) =>{
      if (entry.isIntersecting) {
        entry.target.classList.add('show');
        if (entry.target.classList[0] == 'technology-sub-container-heading') {
          entry.target.classList.add('animate__animated');
          entry.target.classList.add('animate__fadeInLeftSmall');
        } if(entry.target.classList[0] == 'technology-sub-container-1') {
          entry.target.classList.add('animate__animated');
          entry.target.classList.add('animate__fadeInLeftSmall');
          // console.log(entry.target.style)
        } if (entry.target.classList[0] == 'section-heading') {
          entry.target.classList.add('animate__animated');
          entry.target.classList.add('animate__fadeInLeftSmall');
        }
        else {
          entry.target.classList.add('animate__animated');
          entry.target.classList.add('animate__fadeInUp');
        }
      } else {
        entry.target.classList.remove('show');
        if (entry.target.classList[0] == 'technology-sub-container-heading') {
          entry.target.classList.remove('animate__animated');
          entry.target.classList.remove('animate__fadeInLeftSmall');
        } if(entry.target.classList[0] == 'technology-sub-container-1') {
          entry.target.classList.remove('animate__animated');
          entry.target.classList.remove('animate__fadeInLeftSmall');
          // console.log(entry.target.style)
        } if (entry.target.classList[0] == 'section-heading') {
          entry.target.classList.remove('animate__animated');
          entry.target.classList.remove('animate__fadeInLeftSmall');
        }
        else {
          entry.target.classList.remove('animate__animated');
          entry.target.classList.remove('animate__fadeInUp');
        }
      }
    });
  });

  options = {
    background: '#fffff',
  }

  private subject$ = new Subject<{
    entry: IntersectionObserverEntry;
    observer: IntersectionObserver;
  }>();
  constructor(private element: ElementRef, @Inject(DOCUMENT) private _document: HTMLDocument) { }

  ngAfterViewInit(): void {
    // @ts-ignore
    this._document.querySelectorAll('.section-heading').forEach((el)=> this.observer.observe(el))
    this._document.querySelectorAll('.technology-sub-container-heading').forEach((el)=> this.observer.observe(el))
    this._document.querySelectorAll('.technology-sub-container-1').forEach((el)=> this.observer.observe(el))

  }



  ngOnInit(): void {
  }

}
