<div class="card-border" >

  </div>
<div>
</div>
  <div class="card-content animate__animated animate__fadeIn" #cardElement >
    <div class="card-image-wrapper">
      <img class="card-demo-image animate__animated animate__fadeIn" src="{{ resourceBaseURL + cardData.display_image_URL }}"/>
    </div>
      <h2 class="card-heading-main">{{ cardData.title }}</h2>
    <h3 class="card-heading-secondary"> {{ cardData.description }}</h3>
    <mat-chip-listbox class="chip-wrapper" #chip_wrapper id="chip_wrapper" aria-label="Fish selection">
      <mat-chip-option *ngFor=" let previewTag of cardData.preview_tags | slice:0:3;">{{ previewTag }}</mat-chip-option>
    </mat-chip-listbox>

  </div>
