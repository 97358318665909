import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SoftwareDevelopmentComponent } from './components/software-development/software-development.component';
import { GameDevelopmentComponent } from './components/game-development/game-development.component';
import { UiDesignComponent } from './components/ui-design/ui-design.component';
import { PhotographyComponent } from './components/photography/photography.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/common/header/header.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { LoginComponent } from './components/login/login.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule } from "@angular/common/http";
import {ApiService} from "./services/api.service";
import { CreatePostComponent } from './components/create-post/create-post.component';
import { JwtModule } from '@auth0/angular-jwt';
import {AuthGuardService} from "./services/auth-guard.service";
import { QuillModule } from 'ngx-quill';

/* Angular Material Design */
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatSelectModule} from "@angular/material/select";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialLibraryModule} from "../material.module";
import { GridMasonryItemComponent } from './components/common/grid-masonry-item/grid-masonry-item.component';
import { InfoBtnComponent } from './components/common/info-btn/info-btn.component';
import { DisplayCardComponent } from './components/common/display-card/display-card.component';
import { MatChipListComponent } from './components/common/mat-chip-list/mat-chip-list.component';
import { GameDevDialogBoxComponent } from './components/common/dialogBox/game-dev-dialog-box/game-dev-dialog-box.component';


/* External Modules */
import { LightgalleryModule } from 'lightgallery/angular';
import { EditPostComponent } from './components/edit-post/edit-post.component';

@NgModule({
  declarations: [
    AppComponent,
    SoftwareDevelopmentComponent,
    GameDevelopmentComponent,
    UiDesignComponent,
    PhotographyComponent,
    HomeComponent,
    HeaderComponent,
    PagenotfoundComponent,
    LoginComponent,
    CreatePostComponent,
    GridMasonryItemComponent,
    InfoBtnComponent,
    DisplayCardComponent,
    MatChipListComponent,
    GameDevDialogBoxComponent,
    EditPostComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["api.taranjyotsingh.co.uk", "localhost:3000", "127.0.0.1:3000"],
        disallowedRoutes: [],
        throwNoTokenError: false,
      },
    }),
    ReactiveFormsModule,
    QuillModule.forRoot(),
    BrowserAnimationsModule,
    MatSelectModule,
    MaterialLibraryModule,
    LightgalleryModule
  ],
  providers: [ ApiService, AuthGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

