import {Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-mat-chip-list',
  templateUrl: './mat-chip-list.component.html',
  styleUrls: ['./mat-chip-list.component.scss']
})
export class MatChipListComponent {

  // @ts-ignore
  @Output('onCategoryChange') onCategoryChange: EventEmitter<any> = new EventEmitter();
  @Input()
  categoriesList = [
    {
      title: '',
      count: 0,
      selected: false
    }
  ];

  @Input()
  displayCount: boolean = false;

  @Input()
  previousIndexSelected: number = 0;

  changeCategory(categoryIndexSelected: number): void {
    if(categoryIndexSelected == this.previousIndexSelected) {
      this.categoriesList[categoryIndexSelected].selected = true;
      return;
    }

    this.categoriesList[this.previousIndexSelected].selected = false;
    this.categoriesList[categoryIndexSelected].selected = true;
    this.previousIndexSelected = categoryIndexSelected;
    this.onCategoryChange.emit(this.categoriesList[categoryIndexSelected].title);
  }

}
