import {Component, OnInit} from '@angular/core';
import { Router, ActivatedRoute} from "@angular/router";
import {ApiService} from "../../services/api.service";
import {MatChipEditedEvent, MatChipInputEvent} from "@angular/material/chips";
import {COMMA, ENTER} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  htmlText = "<p>Testing</p>"
  hasFocus = false;
  subject: string = '';
  postId = '';
  responseData: any = {};

  inputSettings: any = {
    categories: ['game-development', 'software-development', 'ui-design', 'photography'],
    section_tags: [],
    project_tags: [],
    technology_tags: [],
    display_image_data: Object,
    images: [],
    category: 'game-development',
    videos_type: ['iframe','src']
  };

  atValues = [
    {id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com'},
    {id: 2, value: 'Patrik Sjölin'}
  ];
  hashValues = [
    {id: 3, value: 'Fredrik Sundqvist 2'},
    {id: 4, value: 'Patrik Sjölin 2'}
  ]

  quillConfig = {
    //toolbar: '.toolbar',
    blotFormatter: {},
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{'header': 1}, {'header': 2}],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //[{ 'font': [] }],
        //[{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['link', 'image', 'video'],
      ],

    },

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      source: (searchTerm: any, renderList: any, mentionChar: any) => {
        let values;

        if (mentionChar === "@") {
          values = this.atValues;
        } else {
          values = this.hashValues;
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (var i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
    },
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            console.log("enter");
            return true;
          }
        }
      }
    }
  }



  constructor(private router: ActivatedRoute, private APIService: ApiService) {
  }

  ngOnInit(): void {
    this.router.params.subscribe(params => {
      this.postId = params['id'];
      console.log(this.postId)
      this.APIService.getPostByID(this.postId).then((resp)=>{
        console.log(resp);
        this.responseData = resp;
      }).catch(err=>{
        console.error(err);
      })
    });
  }

  add(event: MatChipInputEvent, tagType: String): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      if(tagType == 'technology') {
        if(!this.responseData.technology_tags.includes(value,0))
          this.responseData.technology_tags.push(value);
      }

      if(tagType == 'project') {
        if(!this.responseData.project_tags.includes(value, 0))
          this.responseData.project_tags.push(value);
      }

    }

    // Clear the input value
    event.chipInput!.clear();
  }

  OnRemoveScreenshotItem(index:number) {
    this.inputSettings.images.splice(index,1);
    console.log(this.inputSettings.images)
  }

  onDisplayImageSelected($event:any): void {
    this.inputSettings.display_image_data = $event.target.files[0];
    console.log(this.inputSettings);
  }

  /***
   * removees section tag
   * @param section
   */
  removeSection(section: any, tagType: String) {

    if(tagType == 'technology') {
      const index = this.responseData.technology_tags.indexOf(section);
      if (index >= 0) {
        this.responseData.technology_tags.splice(index, 1);
      }
    }

    if(tagType == 'project') {
      const index = this.responseData.project_tags.indexOf(section);
      if (index >= 0) {
        this.responseData.project_tags.splice(index, 1);
      }
    }


  }

  onScreenshotsSelected($event:any): void {
    console.log($event.target.files[0])

    for(let i =0; i< $event.target.files.length;i++) {
      let imageObj = {
        'image_title': $event.target.files[i]['name'],
        'image_description': '',
        'image_url': '',
        'image_data': $event.target.files[i]
      }
      this.inputSettings.images.push(imageObj)
      // /this.inputSettings.images.append(imageObj);
    }
  }

  OnRemoveVideoURLItem(index: number) {
    this.responseData.videos.splice(index,1);
  }

  AddVideoURLItem() {
    this.responseData.videos.push({
      video_url: '',
      video_type: this.inputSettings.videos_type[0],
    });
  }

  onSelectionChanged = (event: any) => {
    if (event.oldRange == null) {
      this.onFocus();
    }
    if (event.range == null) {
      this.onBlur();
    }
  }

  onFocus = () => {
    console.log("On Focus");
  }
  onBlur = () => {
    console.log("Blurred");
  }

  onContentChanged = (event: any) => {
    // console.log(event.html);
  }

  editSection(section: any, tagType: String, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove tag if it no longer has a name
    if (!value) {
      this.removeSection(section, tagType);
      return;
    }

    // Edit tag
    if (tagType == 'technology') {
      const index = this.responseData.technology_tags.indexOf(section);
      if (index > 0) {
        this.responseData.technology_tags[index] = value;
      }
    }
  }

  updatePost() {
    const data = structuredClone(this.responseData);
    //const arr =
    data.categories = [this.inputSettings.category];
    data.images = this.inputSettings.images;

    const formData: FormData = new FormData();
    formData.append('fields',JSON.stringify(data));

    formData.append('display_image_data', this.inputSettings.display_image_data);
    for (let i =0;i < this.inputSettings.images.length; i++) {
      formData.append('images' + i, this.inputSettings.images[i].image_data);
    }
    console.log(this.inputSettings.images);

    this.APIService.updatePost(this.responseData.id, formData);
  }
}
