<div class="chip-wrapper-body container">
  <mat-chip-listbox selectable class="chip-wrapper" *ngIf="displayCount ==false">
    <mat-chip-option
      ng-class="{largeWidth: myVar == 'ok', smallWidth: myVar != 'ok'}"
      *ngFor="let category of categoriesList; let i = index"
      color="accent"
      [class.accent-color-chip-btn]="category.selected"
      [selected]="category.selected"
      [selectable]="!category.selected"
      (selectionChange)="changeCategory(i)">{{ category.title   }}
    </mat-chip-option>
  </mat-chip-listbox>

  <mat-chip-listbox class="chip-wrapper " *ngIf="displayCount">
    <mat-chip-option *ngFor="let category of categoriesList; let i = index"
                      color="accent" [selected]="category.selected"

                      [selectable]="!category.selected"
                      [class.accent-color-chip-btn]="category.selected"
                      class="animate__animated animate__fadeInRight"
                      [ngStyle]="{'--animate-repeat':1000000000000}"
                      (selectionChange)="changeCategory(i)">{{ category.title + ' (' + category.count + ')'  }}</mat-chip-option>
  </mat-chip-listbox>
</div>
