<app-header [currentRoute]="'photography'"></app-header>

<div class="masonry-with-columns" id="masonry-with-columns">
  <app-grid-masonry-item></app-grid-masonry-item>

</div>
<div class="container-sm">
  <div class="row justify-content-center">
    <div class="col col-md-12">

      <lightgallery class="photography-wrapper" [settings]="settings" [onInit]="onInit" [onBeforeSlide]="onBeforeSlide">
        <a (click)="openGallery(i)" *ngFor="let photographyCard of photographItems; let i = index" class="gallery-item animate__animated animate__fadeIn"
           [attr.data-src]="photographyCard.src"
           [attr.data-responsive]="photographyCard.src"
           [attr.data-sub-html]="photographyCard.subHtml">
          <img class="img-responsive"
               src="{{photographyCard.src }}"/>
          <button *ngIf="showAuthBtn" (click)="deletePost($event, photographyCard)" class="deleteBtn animate__animated animate__fadeIn">
            <i class="fa-solid fa-trash"></i> DELETE POST
          </button>
        </a>

      </lightgallery>
<!--      <lightgallery  [onInit]="onInit" class="photography-wrapper" [settings]="settings" [onBeforeSlide]="onBeforeSlide">-->
<!--        <a (click)="this.openGallery(i)" *ngFor="let photographyCard of photographItems; let i = index" class="gallery-item">-->
<!--          <img class="img-responsive"-->
<!--               src="{{ photographyCard['src']}}" />-->
<!--        </a>-->


<!--&lt;!&ndash;        <a data-pinterest-text="Shinimamiya, Osaka, Japan"&ndash;&gt;-->
<!--&lt;!&ndash;           data-tweet-text="Shinimamiya, Osaka, Japan" class="gallery-item"&ndash;&gt;-->
<!--&lt;!&ndash;           data-src="https://images.unsplash.com/photo-1544550285-f813152fb2fd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1400&q=80"&ndash;&gt;-->
<!--&lt;!&ndash;           data-responsive="https://images.unsplash.com/photo-1544550285-f813152fb2fd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=480&q=80 480, https://images.unsplash.com/photo-1544550285-f813152fb2fd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=800&q=80 800"&ndash;&gt;-->
<!--&lt;!&ndash;           data-sub-html="<h4>Photo by - <a href='https://unsplash.com/@asoshiation' >Shah </a></h4><p> Location - <a href='https://unsplash.com/s/photos/shinimamiya%2C-osaka%2C-japan'>Shinimamiya, Osaka, Japan</a></p>">&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="img-responsive"&ndash;&gt;-->
<!--&lt;!&ndash;               src="https://images.unsplash.com/photo-1544550285-f813152fb2fd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80" />&ndash;&gt;-->
<!--&lt;!&ndash;        </a>&ndash;&gt;-->
<!--      </lightgallery>-->
    </div>
  </div>
<!--  <button (click)="addImage()">Add news slide and open gallery</button>-->
</div>
