import {Component, Input, OnInit} from '@angular/core';
import { ApiService } from "../../services/api.service";
import {Router} from "@angular/router";
import { ActivatedRoute } from '@angular/router';
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ApiService]
})
export class LoginComponent implements OnInit {
  @Input('password') password: string = "";
  @Input('email') email: string = "";

  loginForm = this.formBuilder.group({
    email: [''],
    password: ['']
  });

  constructor(private ApiService: ApiService, private route: Router,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.ApiService.isUserAuthenticated().subscribe(
      (next)=> {
        //this.route.navigate(['child component path'], {relativeTo: this.activatedRoute});
      }
    )
  }

  OnLoginSubmit():void {
    console.log(this.loginForm.value)
    if(this.loginForm == null)
      return;
    if (this.loginForm.value.password!=null && this.loginForm.value.email) {
      this.ApiService.login(this.loginForm.value.email, this.loginForm.value.password)
        .subscribe({
        next: (data: any) => {
          console.info("Successfully logged in!!");
          this.route.navigate(['ce8ceed1b5b3143703a5dc67da2098e7/createpost']);
        },error: (error) => {
          console.error("API ERROR" + error.message);
        }
        });
    }

  }
}
