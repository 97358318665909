import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {catchError, Observable, tap, throwError} from "rxjs";
import * as moment from "moment";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseAPIURL: string = '';

  constructor(private http: HttpClient, public jwtHelper: JwtHelperService) {
    this.baseAPIURL = environment.apiURL;
  }

  /**
   * login in api request
   * @param email
   * @param password
   */
  public login(email: string, password: string): Observable<any> {
    return this.http.post(this.baseAPIURL + '/v1/auth/login', {
      'email': email,
      'password': password
    }).pipe(
      tap(data => {
        this.setSession(data);
      }),
      catchError(this.handleError)
    );
  }

  /**
   * login in api request
   * @param payload
   * @param page
   */
  public getPosts(payload: Object, page: string): Promise<any> {
    return new Promise(((resolve, reject) => {
      this.http.post(this.baseAPIURL + '/v1/' + page + '/posts', {
          data: payload
        }
      ).subscribe({
        next: (resp) => {
          resolve(resp);
        },
        error: (resp: any) => {
          reject(resp);
        }
      });
    }));
  }

  /**
   * login in api request
   * @param payload
   * @param page
   */
  public getDistinctTags(payload: Object, page: string): Promise<any> {
    return new Promise(((resolve, reject) => {
      this.http.post(this.baseAPIURL + '/v1/' + page + '/tags', {
          data: payload
        }
      ).subscribe({
        next: (resp) => {
          resolve(resp);
        },
        error: (resp: any) => {
          reject(resp);
        }
      });
    }));
  }


  public isUserAuthenticated()
    :
    Observable<any> {
    return this.http.get(this.baseAPIURL + '/v1/auth', {}).pipe(
      tap(data => {
      }),
      catchError(this.handleError)
    );
  }

  public async getRefreshToken(refreshToken: string) {
    console.log("Helllo bitch")
    /*
    try {
      let result = await this.http.post(this.baseAPIURL + '/v1/auth/refresh-tokens', {
        "refreshToken": refreshToken
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
    */

    this.http.post(this.baseAPIURL + '/v1/auth/refresh-tokens', {
      "refreshToken": refreshToken
    }).subscribe({
      next: (resp) => {
        console.log(resp)
        return true;
      },
      error: (resp: any) => {
        return false;
      }
    });

  }

  public isAuthenticated(): boolean {
    let token = localStorage.getItem('access_token');
    let refreshToken = localStorage.getItem('refresh_token');

    /* @TODO Create refreshToken Api Request
    if (refreshToken === null)
      return false;
    else {
      let resp = this.getRefreshToken(refreshToken);
      console.log(resp)
      return true;
    }
    console.log(refreshToken)
    */

    if (token === null)
      token = '';
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }

  private handleError(err: HttpErrorResponse) {

    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {

      errorMessage = `An error occurred: ${err.error.message}`;
    } else {

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  private setSession(authResult: any): any {
    const expiresAt = moment().add(authResult.expiresIn, 'second');
    localStorage.setItem("access_token", authResult.tokens.access.token);
    localStorage.setItem("refresh_token", authResult.tokens.refresh.token);
  }

  createPost(responseFormData: FormData): void {
    let headers = new HttpHeaders();
    //this is the important step. You need to set content type as null
    // @ts-ignore
    headers.set('Content-Type', null);
    headers.set('Accept', "multipart/form-data");
    let params = new HttpParams();

    this.http.post(this.baseAPIURL + '/v1/post/CreatePost', responseFormData, {params, headers}).subscribe({
      next: (resp) => {
        console.log(resp)
        return true;
      },
      error: (resp: any) => {
        return false;
      }
    });
  }

  createPhotographyPost(responseFormData: FormData): void {
    let headers = new HttpHeaders();
    //this is the important step. You need to set content type as null
    // @ts-ignore
    headers.set('Content-Type', null);
    headers.set('Accept', "multipart/form-data");
    let params = new HttpParams();

    this.http.post(this.baseAPIURL + '/v1/post/CreatePhotographyPost', responseFormData, {params, headers}).subscribe({
      next: (resp) => {
        console.log(resp)
        return true;
      },
      error: (resp: any) => {
        return false;
      }
    });
  }

  updatePost(id: string,responseFormData: FormData): void {
    let headers = new HttpHeaders();
    //this is the important step. You need to set content type as null
    // @ts-ignore
    headers.set('Content-Type', null);
    headers.set('Accept', "multipart/form-data");
    let params = new HttpParams();

    this.http.post(this.baseAPIURL + '/v1/post/update/'+id, responseFormData, {params, headers}).subscribe({
      next: (resp) => {
        console.log(resp)
        return true;
      },
      error: (resp: any) => {
        return false;
      }
    });
  }

  deletePost(id: number) {
    return new Promise(((resolve, reject) => {
      this.http.post(this.baseAPIURL + '/v1/post/delete/' + id, {
        }
      ).subscribe({
        next: (resp) => {
          resolve(resp);
        },
        error: (resp: any) => {
          reject(resp);
        }
      });
    }));
  }

  getPostByID(id: string) {
    return new Promise(((resolve, reject) => {
      this.http.post(this.baseAPIURL + '/v1/post/fetch/' + id, {
        }
      ).subscribe({
        next: (resp) => {
          resolve(resp);
        },
        error: (resp: any) => {
          reject(resp);
        }
      });
    }));
  }

  getAllTagsAndCategories() {
    return new Promise(((resolve, reject) => {
      this.http.post(this.baseAPIURL + '/v1/post/tags', {

      }
      ).subscribe({
        next: (resp) => {
          resolve(resp);
        },
        error: (resp: any) => {
          reject(resp);
        }
      });
    }));
  }

  public logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    window.location.reload();
  }
}
