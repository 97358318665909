import {Component, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";
import {FormControl, FormGroup} from "@angular/forms";
import {MatChipEditedEvent, MatChipInputEvent} from '@angular/material/chips';
import 'quill-mention';
import 'quill-emoji';
import * as QuillNamespace from 'quill';

let Quill: any = QuillNamespace;

// @ts-ignore
//import * as ImageDrop from 'quill-image-drop-module';
// @ts-ignore
//import * as ImageResize from 'quill-image-resize-module';

// from the index, which exports a lot of useful modules
import BlotFormatter from 'quill-blot-formatter';
import {HttpHeaders} from "@angular/common/http";

Quill.register('modules/blotFormatter', BlotFormatter);

@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
  providers: [ApiService]
})
export class CreatePostComponent implements OnInit {
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  htmlText = "<p>Testing</p>"
  hasFocus = false;
  subject: string = '';
  responseData: any = {
    title: '',
    description: '',
    categories: '',
    display_image_data: '',
    images_length: 0,
    project_tags: [],
    technology_tags: [],
    description_blog: '',
    links: [
      {
        link_url: '',
        title: 'artstation'
      },
      {
        link_url: 'https://www.instagram.com/taransodyssey/',
        title: 'instagram'
      },
      {
        link_url: '',
        title: 'liveproject'
      },
      {
        link_url: '',
        title: 'documentPDF'
      },
      {
        link_url: '',
        title: 'figma'
      },
    ],
    videos:[]
  };
  inputSettings: any = {
    categories: ['game-development', 'software-development', 'ui-design', 'photography'],
    section_tags: [],
    project_tags: [],
    technology_tags: [],
    display_image_data: Object,
    images: [],
    category: 'game-development',
    videos_type: ['iframe','src'],
    allTags: {},
    complete_project_tags: [],
    complete_technology_tags: []
  };

  ngOnInit(): void {
    this.responseData.categories = this.inputSettings.categories[0];

    this.ApiService.getAllTagsAndCategories().then(resp => {
      console.log(resp);
      this.inputSettings.allTags = resp;
      this.onCategoryChange();
    }).catch(err=>{
      console.error(err)
    });
  }

  atValues = [
    {id: 1, value: 'Fredrik Sundqvist', link: 'https://google.com'},
    {id: 2, value: 'Patrik Sjölin'}
  ];
  hashValues = [
    {id: 3, value: 'Fredrik Sundqvist 2'},
    {id: 4, value: 'Patrik Sjölin 2'}
  ]

  quillConfig = {
    //toolbar: '.toolbar',
    blotFormatter: {},
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['code-block'],
        [{'header': 1}, {'header': 2}],               // custom button values
        [{'list': 'ordered'}, {'list': 'bullet'}],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction

        [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
        //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        //[{ 'font': [] }],
        //[{ 'align': [] }],

        ['clean'],                                         // remove formatting button

        ['link'],
        ['link', 'image', 'video'],
      ],

    },

    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      source: (searchTerm: any, renderList: any, mentionChar: any) => {
        let values;

        if (mentionChar === "@") {
          values = this.atValues;
        } else {
          values = this.hashValues;
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (var i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
    },
    "emoji-toolbar": true,
    "emoji-textarea": false,
    "emoji-shortname": true,
    keyboard: {
      bindings: {
        // shiftEnter: {
        //   key: 13,
        //   shiftKey: true,
        //   handler: (range, context) => {
        //     // Handle shift+enter
        //     console.log("shift+enter")
        //   }
        // },
        enter: {
          key: 13,
          handler: (range: any, context: any) => {
            console.log("enter");
            return true;
          }
        }
      }
    }
  }

  constructor(private ApiService: ApiService, private route: Router) {

  }

  test = (event: any) => {
    console.log(event.keyCode);
  }

  onSelectionChanged = (event: any) => {
    if (event.oldRange == null) {
      this.onFocus();
    }
    if (event.range == null) {
      this.onBlur();
    }
  }

  onContentChanged = (event: any) => {
    // console.log(event.html);
  }

  onFocus = () => {
    console.log("On Focus");
  }
  onBlur = () => {
    console.log("Blurred");
  }

  /***
   * removees section tag
   * @param section
   */
  removeSection(section: any, tagType: String) {

    if(tagType == 'technology') {
      const index = this.responseData.technology_tags.indexOf(section);
      if (index >= 0) {
        this.responseData.technology_tags.splice(index, 1);
      }
    }

    if(tagType == 'project') {
      const index = this.responseData.project_tags.indexOf(section);
      if (index >= 0) {
        this.responseData.project_tags.splice(index, 1);
      }
    }


  }

  editSection(section: any, tagType: String, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove tag if it no longer has a name
    if (!value) {
      this.removeSection(section, tagType);
      return;
    }

    // Edit tag
    if(tagType == 'technology') {
      const index = this.responseData.technology_tags.indexOf(section);
      if (index > 0) {
        this.responseData.technology_tags[index] = value;
      }
    }

    if(tagType == 'project') {
      const index = this.responseData.project_tags.indexOf(section);
      if (index > 0) {
        this.responseData.project_tags[index] = value;
      }
    }

  }


  add(event: MatChipInputEvent, tagType: String): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) {
      if(tagType == 'technology') {
        if(!this.responseData.technology_tags.includes(value,0))
          this.responseData.technology_tags.push(value);
      }

      if(tagType == 'project') {
        if(!this.responseData.project_tags.includes(value, 0))
          this.responseData.project_tags.push(value);
      }

    }

    // Clear the input value
    event.chipInput!.clear();
  }

  onDisplayImageSelected($event:any): void {
    this.inputSettings.display_image_data = $event.target.files[0];
    console.log(this.inputSettings);
  }

  onScreenshotsSelected($event:any): void {
    console.log($event.target.files[0])

    for(let i =0; i< $event.target.files.length;i++) {
      let imageObj = {
        'image_title': $event.target.files[i]['name'],
        'image_description': '',
        'image_url': '',
        'image_data': $event.target.files[i]
      }
      this.inputSettings.images.push(imageObj)
      // /this.inputSettings.images.append(imageObj);
    }
  }
  OnRemoveScreenshotItem(index:number) {
    this.inputSettings.images.splice(index,1);
    console.log(this.inputSettings.images)
  }



  async fileListToBase64(fileList: any) {
    // create function which return resolved promise
    // with data:base64 string
    function getBase64(file: Blob) {
      const reader = new FileReader()
      return new Promise(resolve => {
        reader.onload = ev => {
          // @ts-ignore
          resolve(ev.target.result)
        }
        reader.readAsDataURL(file)
      })
    }
    // here will be array of promisified functions
    const promises = []

    // loop through fileList with for loop
    for (let i = 0; i < fileList.length; i++) {
      promises.push(getBase64(fileList[i]))
    }

    // array with base64 strings
    return await Promise.all(promises)
  }

  AddVideoURLItem() {
    this.responseData.videos.push({
      video_url: '',
      video_type: this.inputSettings.videos_type[0],
    });
  }

  OnRemoveVideoURLItem(index: number) {
    this.responseData.videos.splice(index,1);
  }


  // @ts-ignore
  async uploadPost() {
    console.log(this.inputSettings.category);

    const data = structuredClone(this.responseData);
    //const arr =
    data.categories = [this.inputSettings.category];
    data.images = this.inputSettings.images;
    // let result = await Promise.all(this.getBase64(data.display_image_data));
    //console.log(result);
    const formData: FormData = new FormData();
    formData.append('fields',JSON.stringify(data));
    // try {
    //   const imageDataArray = await this.fileListToBase64([this.inputSettings.display_image_data]);
    //   console.log(imageDataArray)
    //   data.display_image_data = imageDataArray[0];
    // } catch (e) {
    //
    // }

    formData.append('display_image_data', this.inputSettings.display_image_data);
    for (let i =0;i < this.inputSettings.images.length; i++) {
      formData.append('images' + i, this.inputSettings.images[i].image_data);
    }
    console.log(this.inputSettings.images);

    if(this.inputSettings.category == 'photography') {
      this.ApiService.createPhotographyPost(formData);
    } else {
      this.ApiService.createPost(formData);
    }
  }

  onCategoryChange() {
    console.log(this.inputSettings.allTags);
    this.inputSettings.complete_project_tags = this.inputSettings.allTags.projects[this.inputSettings.category];
    this.inputSettings.complete_technology_tags = this.inputSettings.allTags.technology[this.inputSettings.category];
    console.log(this.inputSettings.complete_project_tags);
    console.log(this.inputSettings.category)
  }

  OnProjectTagsClick(value: string, tagType: string) {
    console.log("OnProjectTagsClick called")
    if (value) {
      if(tagType == 'technology') {
        if(!this.responseData.technology_tags.includes(value,0))
          this.responseData.technology_tags.push(value);
      }

      if(tagType == 'project') {
        if(!this.responseData.project_tags.includes(value, 0))
          this.responseData.project_tags.push(value);
      }

    }
  }
}
