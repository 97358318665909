import {Component, Inject, Pipe, PipeTransform, SecurityContext} from '@angular/core';
import lgZoom from "lightgallery/plugins/zoom";
import {BeforeSlideDetail} from "lightgallery/lg-events";
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "../../../../../environments/environment";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-game-dev-dialog-box',
  templateUrl: './game-dev-dialog-box.component.html',
  styleUrls: ['./game-dev-dialog-box.component.scss']
})

export class GameDevDialogBoxComponent {
  resourceBaseURL = '';
  showAuthBtn = false;
  constructor(@Inject(MAT_DIALOG_DATA) public cardData: any, public sanitizer: DomSanitizer, private authService: ApiService,
              private router: Router) {

    this.resourceBaseURL = environment.resourceURL;
    this.showAuthBtn = this.authService.isAuthenticated();
  }
  transformIframeURLs() {
    if(this.cardData.videos!=null || this.cardData.videos.length!=0) {
      for(let i in this.cardData.videos) {
        //this.cardData.videos[i].video_url = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.cardData.videos[i].video_url);
        this.cardData.videos[i].video_url = this.sanitizer.bypassSecurityTrustResourceUrl( this.cardData.videos[i].video_url);
      }
    }
    //console.log(url);
    //return this.sanitizer.bypassSecurityTrustResourceUrl( url);
  }
  settings = {
    counter: false,
    plugins: [lgZoom],
    download: false,
  };
  onBeforeSlide = (detail: BeforeSlideDetail): void => {
    const { index, prevIndex } = detail;
  };

  ngOnInit() {
    this.transformIframeURLs()

  }


  editPost($event: MouseEvent) {

    this.router.navigate(['/ce8ceed1b5b3143703a5dc67da2098e7/edit/post', this.cardData.id]);
  }

  deletePost($event: any) {
    this.authService.deletePost(this.cardData.id).then((resp)=> {
      window.location.reload();
    }).catch(e=> {
      console.error(e);
    });
  }
}
