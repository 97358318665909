import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-ui-design',
  templateUrl: './ui-design.component.html',
  styleUrls: ['./ui-design.component.scss']
})
export class UiDesignComponent implements OnInit {
  gameDevAPIData = [];
  categoriesTagsAPIData = [];
  constructor(private ApiService: ApiService) { }

  ngOnInit(): void {
    this.getPosts('');
    this.getDistinctTags('');
  }

  @ViewChild('card') card!: ElementRef;
  onMouseMove(e: any): void {
    const cards = document.getElementsByClassName('card');
    for(let i=0; i < cards.length; i++) {
      const card = cards[i] as HTMLElement;
      const rect = card.getBoundingClientRect(),
        x = e.clientX - rect.left,
        y = e.clientY - rect.top;
      card.style.setProperty("--mouse-x", `${x}px`);
      card.style.setProperty("--mouse-y", `${y}px`);
    }
  }

  getPosts(tag: string = ''): void {
    let data: Object = {
      categories: "ui-design",
      sort: { createdAt: -1 }
    };

    if (tag != '') {
      // fetch post with tags
      Object.assign(data, {
        project_tags: tag,
        technology_tags: tag
      });
    }
    let self = this;
    this.ApiService.getPosts(data, 'ui-design').then((responseData) => {
        this.gameDevAPIData = responseData;
      }
    ).catch( (error: Error) => {
        console.error(error);
      }
    );
    return;
  }

  getDistinctTags(tag: string = ''): void {
    let data: Object = {
      category: "ui-design",
      search_key: 'preview_tags',
    };

    let self = this;
    this.ApiService.getDistinctTags(data, 'ui-design').then((responseData) => {
        this.categoriesTagsAPIData = responseData;
      }
    ).catch( (error: Error) => {
        console.error(error);
      }
    );
    return;
  }

  onCategoryChange(title: any): void {
    if(title == 'Show All') {
      this.gameDevAPIData = [];
      this.getPosts('');
      return;
    }
    this.gameDevAPIData = [];
    this.getPosts(title);
  }
}
