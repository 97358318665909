<div class="dialog-box-heading">
  <h3 class="dialog-box-heading">{{ cardData.title }}</h3>

  <button *ngIf="showAuthBtn" (click)="deletePost($event)" class="deleteBtn animate__animated animate__fadeIn">
    <i class="fa-solid fa-trash"></i> DELETE POST
  </button>

  <button *ngIf="showAuthBtn" (click)="editPost($event)" class="editBtn animate__animated animate__fadeIn">
    <i class="fa-regular fa-edit"></i> EDIT
  </button>
  <button class="animate__animated animate__fadeIn" mat-dialog-close><i class="fa-solid fa-xmark"></i></button>
</div>
<mat-dialog-content>

  <div class="dialog-box-container">
    <lightgallery class="photography-wrapper" [settings]="settings" [onBeforeSlide]="onBeforeSlide">
    <img class="banner-image animate__animated animate__fadeIn" src="{{resourceBaseURL + cardData.display_image_URL}}" alt=""/>
    </lightgallery>

    <div class="site-share-icons">
      <ng-container *ngFor="let link of cardData.links">
        <div *ngIf="link.link_url !== ''" class="share-icon animate__animated animate__fadeInRight ">
          <a class="share-written" *ngIf="link.title == 'liveproject'" href="{{ link.link_url}}">
            <i class="fa-solid fa-code"></i> <i class="share-written">Live Project</i>
          </a>
          <a *ngIf="link.title == 'instagram'" href="{{ link.link_url}}">
            <i class="fa-brands fa-instagram "></i>
          </a>
          <a *ngIf="link.title == 'artstation'" href="h{{ link.link_url}}">
            <i class="fa-brands fa-artstation"></i>
          </a>
          <a  class="share-written" *ngIf="link.title == 'figma'" href="{{ link.link_url}}">
            <i class="fa-brands fa-figma"></i><i class="share-written">Figma</i>
          </a>
          <a class="share-written" *ngIf="link.title == 'documentPDF'" href="{{ link.link_url}}">
            <i class="fa-regular fa-file"></i><i class="share-written">Report</i>
          </a>
        </div>
      </ng-container>
    </div>

    <div class="dialog-box-content" *ngIf=" cardData.description != null">
      <div class="dialog-box-content" *ngIf="cardData.technology_tags.length != 0 && cardData.technology_tags != null">
        <a class="tech-used animate__animated animate__fadeInRight"
           *ngFor=" let technologyTags of cardData.technology_tags">#{{ technologyTags}}  </a>
      </div>
      <h4>Description:</h4>
      <p class="description">{{cardData.description}}</p>
    </div>

    <div class="dialog-box-content" *ngIf="cardData.project_tags.length != 0 && cardData.project_tags != null">
      <h4>Projects:</h4>
      <mat-chip-listbox class="chip_wrapper_center" aria-label="Fish selection">
        <mat-chip class="animate__animated animate__fadeInRight"
                  *ngFor=" let projectTags of cardData.project_tags">{{ projectTags}}</mat-chip>
      </mat-chip-listbox>
    </div>

    <!--    <div class="dialog-box-content" *ngIf="cardData.technology_tags.length != 0 || cardData.technology_tags != null">-->
    <!--      <h4>Technologies Used:</h4>-->
    <!--      <mat-chip-listbox class="chip_wrapper_center" aria-label="Fish selection">-->
    <!--        <mat-chip  *ngFor=" let technologyTags of cardData.technology_tags">{{ technologyTags.title}}</mat-chip>-->
    <!--      </mat-chip-listbox>-->
    <!--    </div>-->

    <div class="photography-wrapper" *ngIf="cardData.videos.length != 0 || cardData.videos.images!=null">
      <iframe *ngFor="let video of cardData.videos" class="gallery-item" [src]="video.video_url" allowfullscreen
              width="416" height="245"></iframe>
    </div>
    <div *ngIf="cardData.images.length != 0 && cardData.images!=null" class="dialog-box-content">
      <h4>Screenshots:</h4>
      <lightgallery class="photography-wrapper" [settings]="settings" [onBeforeSlide]="onBeforeSlide">
        <a *ngFor="let image of cardData.images; let i = index" class="gallery-item"
           [attr.data-src]="resourceBaseURL+ image.image_url"
           [attr.data-responsive]="resourceBaseURL + image.image_url"
           [attr.data-sub-html]="resourceBaseURL + image.image_title">
          <img class="img-responsive animate__animated animate__fadeIn"
               src="{{resourceBaseURL + image.image_url }}"/>
        </a>
      </lightgallery>
    </div>

    <div *ngIf="cardData.description_blog !== ''">
      <!--      <h4>Screenshots:</h4>-->
      <div [innerHTML]="cardData.description_blog">
      </div>
    </div>
  </div>
</mat-dialog-content>

